/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import '~swiper/scss';
// @import '~@ionic/angular/css/ionic-swiper';
@import '~@aws-amplify/ui-angular/theme.css';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

* {
	outline:none;
}

.amplify-text {
    color: #FFF !important;
}

@media only screen and (max-width:600px) {

    ion-modal.custom {
        --height: 100% !important;
        --width: 100% !important;

        --border-width: 0px !important;
        --border-radius: 0 !important;

        ion-content {
            --padding-top: 22px !important;
            --padding-bottom: 22px !important;
            --padding-start: 16px !important;
            --padding-end: 16px !important;
            border-radius: 0 !important;
        }
    }

    .inputWIcon {
        width: 50vw !important;
    }
}

@media only screen and (min-width:600px) {
    .inputWIcon {
        width: 40% !important;
    }
}

option {
    background: #FFF !important;
}

app-tabs{
    height: 100dvh;
}

ion-tab-bar {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

ion-modal.custom {
    --border-radius: 16px;
    --border-color: #FFFFFF;
    --border-width: 1px;
    --border-style: solid;

    ion-content {
        --padding-top: 32px;
        --padding-bottom: 32px;
        --padding-start: 24px;
        --padding-end: 24px;
        border-radius: 16px;
    }

    .modal_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h1 {
            font-weight: bold;
            font-size: 24px;
        }

        img {
            width: 48px;
            cursor: pointer;
        }
    }
}

ion-content::part(scroll)::-webkit-scrollbar {
    display: none;
}

.alert-button-inner {
    color: #FFF;
}

option {
    color: #000;
    background: #FFF
}

.point {
    width: 8px;
    height: 8px;
    background: #C39173;
    border-radius: 100%;
    position: absolute;
    right: 16px;
    top: 16px;

    &.badge {
        color: #FFF;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 7px;
        font-size: 14px;
        font-weight: 600;
    }
}

.styleScrollbar {
    &::-webkit-scrollbar {
        width: 12px;
        /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #686868;
        /* color of the scroll thumb */
        border-radius: 20px;
        /* roundness of the scroll thumb */
        border: 5px solid #000000;
        /* creates padding around scroll thumb */
    }
}

.hideScroll {
    &::-webkit-scrollbar {
        display: none;
        /* width of the entire scrollbar */
    }
}

:root {
    --ion-background-color: #000;
    --ion-text-color: #fff;
}

swiper-container {
    --swiper-theme-color: #fff;
    --swiper-navigation-size: 25px;
}

.pointer {
    cursor: pointer !important;
}

.p4 {
    padding: 4px !important;
}

.p7 {
    padding: 7px !important;
}

.mt-16 {
    margin-top: 16px;
}

@media (prefers-color-scheme: dark) {
    :root {
        --amplify-colors-red-10: hsl(0, 100%, 15%);
        --amplify-colors-red-20: hsl(0, 100%, 20%);
        --amplify-colors-red-40: hsl(0, 95%, 30%);
        --amplify-colors-red-80: hsl(0, 75%, 75%);
        --amplify-colors-red-90: hsl(0, 75%, 85%);
        --amplify-colors-red-100: hsl(0, 75%, 95%);
        --amplify-colors-orange-10: hsl(30, 100%, 15%);
        --amplify-colors-orange-20: hsl(30, 100%, 20%);
        --amplify-colors-orange-40: hsl(30, 95%, 30%);
        --amplify-colors-orange-80: hsl(30, 75%, 75%);
        --amplify-colors-orange-90: hsl(30, 75%, 85%);
        --amplify-colors-orange-100: hsl(30, 75%, 95%);
        --amplify-colors-yellow-10: hsl(60, 100%, 15%);
        --amplify-colors-yellow-20: hsl(60, 100%, 20%);
        --amplify-colors-yellow-40: hsl(60, 95%, 30%);
        --amplify-colors-yellow-80: hsl(60, 75%, 75%);
        --amplify-colors-yellow-90: hsl(60, 75%, 85%);
        --amplify-colors-yellow-100: hsl(60, 75%, 95%);
        --amplify-colors-green-10: hsl(130, 22%, 23%);
        --amplify-colors-green-20: hsl(130, 27%, 29%);
        --amplify-colors-green-40: hsl(130, 33%, 37%);
        --amplify-colors-green-80: hsl(130, 44%, 63%);
        --amplify-colors-green-90: hsl(130, 60%, 90%);
        --amplify-colors-green-100: hsl(130, 60%, 95%);
        --amplify-colors-teal-10: hsl(190, 100%, 15%);
        --amplify-colors-teal-20: hsl(190, 100%, 20%);
        --amplify-colors-teal-40: hsl(190, 95%, 30%);
        --amplify-colors-teal-80: hsl(190, 70%, 70%);
        --amplify-colors-teal-90: hsl(190, 75%, 85%);
        --amplify-colors-teal-100: hsl(190, 75%, 95%);
        --amplify-colors-blue-10: hsl(220, 100%, 15%);
        --amplify-colors-blue-20: hsl(220, 100%, 20%);
        --amplify-colors-blue-40: hsl(220, 95%, 30%);
        --amplify-colors-blue-80: hsl(220, 70%, 70%);
        --amplify-colors-blue-90: hsl(220, 85%, 85%);
        --amplify-colors-blue-100: hsl(220, 95%, 95%);
        --amplify-colors-purple-10: hsl(300, 100%, 15%);
        --amplify-colors-purple-20: hsl(300, 100%, 20%);
        --amplify-colors-purple-40: hsl(300, 95%, 30%);
        --amplify-colors-purple-80: hsl(300, 70%, 70%);
        --amplify-colors-purple-90: hsl(300, 85%, 85%);
        --amplify-colors-purple-100: hsl(300, 95%, 95%);
        --amplify-colors-pink-10: hsl(340, 100%, 15%);
        --amplify-colors-pink-20: hsl(340, 100%, 20%);
        --amplify-colors-pink-40: hsl(340, 95%, 30%);
        --amplify-colors-pink-80: hsl(340, 70%, 70%);
        --amplify-colors-pink-90: hsl(340, 90%, 85%);
        --amplify-colors-pink-100: hsl(340, 95%, 95%);
        --amplify-colors-neutral-10: hsl(210, 50%, 10%);
        --amplify-colors-neutral-20: hsl(210, 25%, 25%);
        --amplify-colors-neutral-40: hsl(210, 10%, 40%);
        --amplify-colors-neutral-80: hsl(210, 5%, 87%);
        --amplify-colors-neutral-90: hsl(210, 5%, 94%);
        --amplify-colors-neutral-100: hsl(210, 5%, 98%);
        --amplify-colors-black: #fff;
        --amplify-colors-white: #000;
        --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
        --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
        --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
        --amplify-colors-overlay-10: hsla(0, 0%, 100%, 0.1);
        --amplify-colors-overlay-20: hsla(0, 0%, 100%, 0.2);
        --amplify-colors-overlay-30: hsla(0, 0%, 100%, 0.3);
        --amplify-colors-overlay-40: hsla(0, 0%, 100%, 0.4);
        --amplify-colors-overlay-50: hsla(0, 0%, 100%, 0.5);
        --amplify-colors-overlay-60: hsla(0, 0%, 100%, 0.6);
        --amplify-colors-overlay-70: hsla(0, 0%, 100%, 0.7);
        --amplify-colors-overlay-80: hsla(0, 0%, 100%, 0.8);
        --amplify-colors-overlay-90: hsla(0, 0%, 100%, 0.9);
    }
}

.transparent-modal {
    --background: #000;
    backdrop-filter: blur(12px);

    ion-content {
        --background: transparent;
    }
}

ion-modal {
    --background: #000000;
    --backdrop-opacity: 0.8;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

amplify-authenticator {
    width: 100%;
}

.federated-sign-in-container {
    //flex-direction: column-reverse;
    margin-top: 18px;
    margin-bottom: 12px;
}

.amplify-divider--small{
    display: none;;
}

.amplify-label {
    color: #FFFFFF;
}

.amplify-input {
    color: #FFFFFF;
    border-color: #FFFFFF !important;
}

.amplify-button--primary {
    background: #686868;
    border: 1px solid #686868 !important;

    &:hover,
    &:active {
        background: #C39173 !important;
        color: #FFFFFF !important;
        border: 1px solid #C39173 !important;
    }
}

.amplify-icon {
    color: #FFFFFF;
}

.amplify-input.amplify-button {

    &:hover,
    &:active {
        background: transparent;
        border: 1px solid #FFFFFF;
    }
}

.amplify-button--link {
    margin-top: 32px;
    background: transparent !important;
    border: none !important;
    color: #FFFFFF !important;

    &:hover,
    &:active {
        color: #C39173 !important;
    }
}

[data-amplify-authenticator] [data-amplify-container] {
    place-self: auto;
}

:root {
    --amplify-components-authenticator-container-width-max: 100%;
    --amplify-components-authenticator-form-padding: 0;

    --amplify-components-authenticator-router-background-color: transparent;
    --amplify-components-authenticator-router-border-color: transparent;

    --amplify-components-heading-3-font-size: 24px;
    --amplify-components-heading-color: #FFFFFF;

    --amplify-components-tabs-item-color: #FFFFFF;

    --amplify-components-authenticator-state-inactive-background-color: transparent;
    --amplify-components-tabs-item-active-color: #C39173;
    --amplify-components-tabs-item-focus-color: #C39173;
    --amplify-components-tabs-item-hover-color: #C39173;
    --amplify-components-tabs-item-active-border-color: #C39173;
}

// GOOGLE MAP INFOWINDOW STYLE

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw-tc::after {
    display: none;
}

.gm-style-iw {
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
}

.gm-style-iw-d {
    padding: 0 !important;
    overflow: inherit !important;
    height: fit-content;

    .restor_label {
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 100%;
        font-weight: 400;
        border-radius: 8px;
        font-size: 14px;

        b.green{
            color: green;
        }

        b.red{
            color: red;
        }

        .map_like{
            height: 14px;
        }

        .map_dislike{
            height: 14px;
            margin-top: 3px;
        }
    }

    .restor.restor_modal{

    }
}

.restor_modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: black;
    border-radius: 8px;
    cursor: pointer;

    .row {
        width: 100%;
    }

    .name {
        img {
            height: 17px;
        }
    }

    .gap6 {
        width: 70%;
        padding: 16px;
    }

    h5 {
        font-size: 18px;
    }

    p {
        font-size: 12px;
    }

    .image {
        height: 130px;
        aspect-ratio: 1 / 1;
        background-image: url(/assets/rest2.png);
        background-position: center;
        background-size: cover;
        border-radius: 8px 0 0 8px;
    }


    .address {
        gap: 6px;
        margin-top: 10px;
        font-size: 12px;
        width: -webkit-fill-available;
        justify-content: flex-start;

        img {
            width: 16px;
            height: 16px;
        }

        p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

///////////////////////////

:root {

    --swiper-pagination-color: #C39173;
    --swiper-pagination-bottom: 24px;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #FFFFFF;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;

}

.swiper-button-next,
.swiper-button-prev {
    color: #FFFFFF !important;
}

* {
    font-family: "Montserrat", sans-serif !important;
}

.noVisible {
    visibility: hidden !important;
}

ion-content {
    margin: 0;
    --background: #000000 !important;
    --height: 100vh;
    --padding-top: 0px;
}

ion-alert {
    --background: #121212 !important;
    //--background:red !important;
}

.alert-title {
    color: #FFF !important;
    //color: green !important;
}

.alert-message {
    color: #FFF !important;
    //color: yellow !important;
}

.alert-button-inner {
    color: #FFF !important;
    //color: orange !important;
}

.modalBottom {
    align-items: flex-end;
    --width: 480px;
    --height: 100vh;
    --border-radius: 0 0 0 0 !important;
}

.lightBg {
    app-menu {
        background: rgba(0, 0, 0, 0) !important;
    }
}

.shareModal {
    --width: 350px !important;
    --height: 580px !important;
}

.restaurantModal,
.menuModal {
    --background: transparent !important;
    //--width: 592px !important;
    --width: 100vw;
    --max-width: 100vw;
    --min-width: 100vw;
}

.restaurantModal {
    ion-content {
        .modal_restaurant {
            width: 500px;
            margin-left: calc(50vw - 250px - 56px);
        }
    }
}

.vacancyModal {
    --width: 500px;
}

.menuModal {
    ion-content {
        .modal_menu {
            width: 500px;
            margin-left: calc(50vw - 250px - 56px);
        }
    }
}

app-restaurant,
app-menu {
    display: flex !important;
    flex-direction: row !important;
    background: rgba(0, 0, 0, 0.2) !important;

    .mainWrapper {
        min-height: 100vh;
    }
}

.empty_list {
    text-align: center;
    margin-top: 40vh;

    p {
        font-size: 16px !important;
    }
}

.textarea_counter {
    position: relative;
    width: 100%;
    margin-bottom: 16px;

    textarea {
        margin-bottom: 0;
    }

    .counter {
        text-align: right;
        font-size: 12px;
    }
}

.mainWrapper {
    padding: 22px 16px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: #fff;
}

input,
select,
textarea {
    background: transparent;

    &:focus {
        outline: none;
    }
}

.capitalize {
    text-transform: capitalize !important;
}

a {
    text-decoration: none !important;
    color: #FFF !important;

    &.row {
        text-decoration: none !important;
    }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#logo {
    height: 44px;
    width: 34px;
}

#rules {
    font-size: 16;
    color: #fff;

}

.btnMain {
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    background-color: #C39173;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &.outline {
        border: 1px #fff solid;
        background-color: #000000;
    }

    img {
        position: absolute;
        right: 16px;
        height: 36px;
        top: 7px;
    }
}

.btnOutline {
    height: 24px;
    padding: 0px 12px;
    border-radius: 20px;
    border: 1px #fff solid;
    background-color: #000000;

    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;

    p {
        color: #fff;
        font-size: 12px !important;
    }

    &.active {
        p {
            color: #000000;
        }

        background-color: #fff;
    }
}

.btnBlack {
    width: fit-content;
    padding: 10px 22px;
    color: #fff;
    font-size: 12px;
    background-color: #000000;
    border-radius: 4px;
}

.btnGrey {
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    border-radius: 4px;
    background-color: #686868;
}

.btnWhite {
    color: #000000;
    background-color: #FFFFFF;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

button:disabled,
button[disabled] {
    background-color: #686868;
}

input {
    border: 1px #fff solid;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    padding: 15px;
}

select {
    border: 1px #fff solid;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
}

.inputWIcon {
    border: 1px #fff solid;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    padding: 15px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
        border: none;
        border-radius: 0;
        height: 20px;
        padding: 0;
    }

    img {
        width: 24px;
        height: 24px;
    }
}


.mt-32 {
    margin-top: 32px;
}

.mt-16 {
    margin-top: 16px;
}


.mt-8 {
    margin-top: 8px;
}


.mb-8 {
    margin-bottom: 8px;
}

h2 {
    font-weight: 600;
    font-size: 20px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.gap32 {
    gap: 32px;
}

.gap8 {
    gap: 8px;
}

.gap16 {
    gap: 16px;
}

.gap12 {
    gap: 12px;
}

.gap6 {
    gap: 6px;
}

.gap4 {
    gap: 4px;
}

.back {
    gap: 16px;
    justify-content: flex-start;

    h1 {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 0 !important;
    }

    img {
        cursor: pointer;
    }
}

.divider {
    background-color: #fff;
    height: 1px;
    width: 100%;
}

.notif {
    display: flex;
    flex-direction: column;
    gap: 6px;

    p {
        font-size: 12px;
        font-weight: 400;
    }
}

h5 {
    font-size: 14px;
    font-weight: 500;
}

h6 {

    font-size: 14px;
    font-weight: 400;
}

span {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

ion-textarea {
    width: 100%;
    margin-top: 37px;
    margin-bottom: 40px;
    border: 1px #fff solid;
    border-radius: 4px;
    padding: 15px;
    color: #fff;
    font-size: 16px;
    resize: none;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    --highlight-color-focused: white !important;
    --highlight-color-valid: white !important;
    --highlight-color-invalid: white !important;
}

textarea {
    width: 100%;
    margin-top: 37px;
    margin-bottom: 40px;
    border: 1px #fff solid;
    border-radius: 4px;
    padding: 15px;
    color: #fff;
    font-size: 16px;
    resize: none;
}


ion-modal.bigmodal {
    --height: calc(100% - 96px);
    align-items: flex-end;
    --border-radius: 16px 16px 0 0;
    --width: 480px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.left {}

.right {}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.map-label {
    background: #000;
    padding: 3px 6px;
    font-weight: 400;
    border-radius: 8px;
    font-size: 6px;
    margin-top: -48px;
}

.smallSelect {
    height: fit-content;
    width: fit-content;
    padding: 4px 12px !important;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &.extra {
        padding: 4px 6px !important;
        font-size: 10px;
        width: 72px;
    }

    &.extra.fitContent {
        width: fit-content;
    }
}

.not_available {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #FFF;
}